:root {
	--color-main: #0049FF;
	--color-secondary: #FFF24D;
	--color-black: #000000;
	--color-white: #ffffff;
	--color-gray: #7A7A7A;
	--color-gray-2: #CCCCCC;
	--color-gray-3: #F8F8F8;
}

html {
  min-height: 100%;
  font-size: 100%;
  line-height: 1.5;
  font-family: 'Source Serif Pro', Roboto Mono, Consolas, Monaco, Andale Mono, monospace
}

body {
  height: 100%;
  margin: 0;
  color: var(--color-black);
  background: url(/assets/img/fondo_kenyilarios.svg) right bottom no-repeat var(--color-white);
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 72px;
  margin-bottom: 4.5rem
}

.pageHeader-logo {
  max-width: 150px
}

.pageHeader-logo-link {
  display: block
}

.pageHeader-logo-image {
  display: block;
  max-width: 100%;
  height: auto
}

.menuButton {
	font-family: 'Space Grotesk', sans-serif;
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: 700;
  color: var(--color-main);
  padding: 0 0 4px;
  border-bottom: 1px solid var(--color-main);
  cursor: pointer;
}

.menuButton:hover {
	color: var(--color-black);
	border-color: var(--color-black);
}

.hero {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding: 0 1em
}

.hero-title {
	font-family: 'Space Grotesk', sans-serif;
	text-align: center;
	font-weight: normal;
	font-size: 3.75em;
	margin-bottom: 2rem;
}

.cta {
  text-align: center;
  margin: 0 auto;
  width: 95%;
  max-width: 480px;
  max-width: 30rem
}

.cta-text {
  font-size: 1rem;
  margin: 0 0 1rem
}

.cta-button {
  display: inline-block;
	font-family: 'Space Grotesk', sans-serif;
  color: var(--color-white);
  background-color: var(--color-main);
  font-size: .875em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  border: 0;
  border-radius: 30px;
  padding: .5em 1.5em;
  box-sizing: border-box;
}

.cta-button:hover {
	background-color: var(--color-black);
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none
}

.sidebar-open.sidebar-wrapper {
  pointer-events: all
}

.sidebar-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
  opacity: 0;
  -webkit-transition: opacity 233ms cubic-bezier(0, 0, .21, 1);
  transition: opacity 233ms cubic-bezier(0, 0, .21, 1)
}

.sidebar-open.sidebar-wrapper:before {
  opacity: .6;
}

.sidebar {
  width: 100%;
  max-width: 25em;
  background-color: #fff;
  height: 100%;
  position: absolute;
  right: -25em;
  -webkit-transition: right 233ms cubic-bezier(0, 0, .21, 1);
  transition: right 233ms cubic-bezier(0, 0, .21, 1);
  overflow-y: auto
}

.sidebar-open .sidebar {
  right: 0
}

.sidebar-actions {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  padding: 1em;
  text-align: right;
	margin-bottom: 3rem;
}

.sidebar-section {
  padding: 0 2em;
  border-bottom: 1px solid var(--color-gray-2);
	margin-bottom: 1.5rem;
}

.sidebar-section:last-child {
  border-bottom: none
}

.sidebar-section-title {
	font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  font-size: 1.25em;
	margin: 0 0 1.5rem;
}

.sidebar-section-text {
  font-size: 1em;
	margin-bottom: 1.5rem;
}

.contactLinks {
	font-family: 'Space Grotesk', sans-serif;
  list-style: none;
  margin: 0 0 24px;
  margin: 0 0 1.5rem;
  padding: 0
}

.contactLinks-item {
  margin-bottom: 24px;
  margin-bottom: 1.5rem
}

.contactLinks-item-label {
  display: block;
  font-size: .75em
}

.contactLinks-item-link {
  display: inline-block;
  color: var(--color-main);
  text-decoration: none;
  font-size: .8em;
  font-weight: 700;
  padding: .5em 3em;
  border: 1px solid var(--color-main);
  border-radius: 30px;
  transition: box-shadow .15s ease-in-out;
}

.contactLinks-item-link:hover {
	color: var(--color-black);
	border-color: var(--color-black);
}

.notice {
	font-family: 'Space Grotesk', sans-serif;
  font-size: .75em;
  margin: 1.5rem 0
}
